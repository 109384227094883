<template>
  <div class="checkout-container">
    <v-container
      class="checkout"
      :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }"
    >
      <category-block
        :target="category"
        :container="false"
        position="position1"
        class="category-block category-block-1"
      />
      <div class="checkout-title">
        <CategoryTitle
          :category="category"
          :showOnMobile="true"
          :showDescription="true"
          :addContainer="false"
        />
      </div>

      <ResponseMessage :response="response" class="px-2" />
      <v-row>
        <v-col
          cols="12"
          md="8"
          lg="8"
          :order="$vuetify.breakpoint.smAndDown ? 'last' : 0"
        >
          <v-row justify="space-between" class="mb-3">
            <v-col cols="12" sm="6" class="pl-2 pl-md-0">
              <CartInfoAddressCard
                :shippingAddress="cart.shippingAddress"
                :editable="editableAddress"
                @edit="openAddressSelector"
                class="w-100"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pr-2 pr-md-0">
              <CartInfoTimeslotCard
                :shippingAddress="cart.shippingAddress"
                :timeslot="cart.timeslot"
                :editable="editableTimeslot"
                @edit="openTimeslotSelector"
                class="w-100 cart-info-timeslot-card"
                :value="timeslot"
              />
            </v-col>
            <v-col cols="12" order="4" class="px-0">
              <CartItemList
                :productsAlternatives="productsAlternatives"
                id="checkout-items-list"
                class="checkout-items-list"
                :full="true"
              />
              <v-alert
                v-if="error"
                type="error"
                border="left"
                transition="v-fab-transition"
              >
                {{ error }}
              </v-alert>
              <div class="checkout-note py-3">{{ $t("checkout.note") }}</div>
            </v-col>
            <!-- <v-col cols="12" sm="4" order-sm="1">
              <CartInfoServiceCard
                :shippingAddress="cart.shippingAddress"
                :editable="editableAddress"
                @edit="openAddressSelector"
                
                class="w-100"
              />
            </v-col> -->
            <v-col cols="12" order="5">
              <div
                class="checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
              >
                <div
                  class="d-flex flex-column flex-md-row mb-2 mb-md-0 w-100 w-sm-auto justify-space-between justify-md-start"
                >
                  <v-btn
                    color="secondary"
                    class="text-uppercase"
                    large
                    text
                    depressed
                    @click="emptyCartConfirm"
                  >
                    <span>{{ $t("checkout.emptyCartBtn") }}</span>
                  </v-btn>
                </div>
                <v-btn
                  class="rounded-pill d-md-none mb-2"
                  block
                  color="secondary"
                  outlined
                  large
                  @click="goToCart"
                >
                  {{ $t("checkout.goBackToCardBtn") }}
                </v-btn>

                <div
                  class="d-flex flex-column flex-md-row justify-space-between justify-md-end w-100 w-sm-auto"
                >
                  <v-btn
                    color="secondary"
                    class="rounded-pill mr-0 mr-md-2 mb-2 mb-md-0 text-uppercase"
                    large
                    depressed
                    outlined
                    @click="addAllToFavorites"
                  >
                    <span>{{ $t("checkout.addToListBtn") }}</span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="rounded-pill text-uppercase"
                    large
                    depressed
                    :disabled="
                      profileLevel < minProfileLevel || cart.totalItems == 0
                    "
                    :loading="loading"
                    @click="goToPayment"
                  >
                    <span>{{ $t("checkout.goToPaymentBtn") }}</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <!-- check if disclaimer is accepted -->
          <CheckoutCompleteProfile
            class="mb-5"
            v-if="!cart.user.profile.confirmed"
          />
          <div
            class="rounded grey lighten-1 pa-3 mb-1"
            v-if="cart.user.fidelityCard"
          >
            <div class="text-body-2 text-uppercase font-weight-bold">
              {{ $t("checkout.points") }}
            </div>
            <div class="d-flex">
              <img
                src="/img_layout/socio-coop.svg"
                alt="Tessera socio coop"
                class="mr-2"
              />
              <h2 class="text-h5 font-weight-bold secondary--text">
                {{ cart.userPoints["98000002"].pointsAvailable }} punti
              </h2>
            </div>

            <div
              class="points-polverized d-flex justify-space-between font-weight-bold mt-4"
              v-if="
                canShowPointsPolvLiguria &&
                  cart.userPoints['86000004'].pointsAvailable
              "
            >
              <div class="">
                {{ $t("checkout.pointsPolv.pointsPolvAvailable") }}
              </div>
              <div class="">
                {{
                  $n(cart.user.userPoints["86000004"].value / 100, "currency")
                }}
              </div>
            </div>

            <router-link
              class="go-to-polverize-points text-decoration-none font-weight-bold mt-4 d-block"
              v-if="
                canShowPointsPolvLiguria &&
                  cart.userPoints['98000002'].pointsAvailable > 0
              "
              :to="{ name: 'PointsPolv' }"
            >
              {{ $t("checkout.pointsPolv.goToPolverizePoints") }}
              <v-icon color="primary" small>$next</v-icon>
            </router-link>
          </div>
          <!-- <gift-list
              v-if="giftCertificates.length > 0"
              :mode="'checkout'"
              :title="$t('checkout.giftCodeSubtitle')"
              :giftList="giftCertificates"
              @reload="loadGiftCertificates"
            /> -->
          <GiftCode
            :giftCertificates="giftCertificates"
            class="mb-3 py-1"
            @add="loadGiftCertificates"
            @remove="loadGiftCertificates"
          />

          <template
            v-if="
              giftCertificatesReceived && giftCertificatesReceived.length > 0
            "
          >
            <div
              v-for="gift in giftCertificatesReceived"
              :key="gift.userGiftCertificateId"
              class="gift-certificate-received d-flex align-center py-4"
            >
              <v-avatar color="secondary" class="mr-4" size="48" rounded>
                <span class="white--text text-h5">
                  {{ gift.amount }}&euro;
                </span>
              </v-avatar>
              <div class="gift-certificate-content flex-grow-1">
                <span class="text-body-2 font-weight-bold">
                  {{ gift.description }}
                </span>
                <div
                  v-if="gift.endDate"
                  class="expiration-date text-caption grey lighten-3"
                  v-html="
                    `<span class='font-weight-bold text-uppercase'>${$t(
                      'checkout.expires'
                    )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(gift.endDate).format('DD/MM')}`
                  "
                ></div>
              </div>
              <v-btn
                color="primary"
                depressed
                small
                @click.stop="addUserGift(gift.userGiftCertificateId)"
              >
                {{ $t("benefits.useBtn") }}
              </v-btn>
            </div>
          </template>

          <CheckoutSummary :orderCart="cart" @removeGiftCode="removeGift" />
          <category-block
            :target="category"
            :container="false"
            position="position3"
            class="category-block category-block-3"
          />
          <category-block
            :target="category"
            :container="false"
            position="position4"
            class="category-block category-block-4"
          />
          <category-block
            :target="category"
            :container="false"
            position="position5"
            class="category-block category-block-5"
          />
        </v-col>
      </v-row>
      <category-block
        :target="category"
        :container="false"
        position="position2"
        class="category-block category-block-2"
      />
    </v-container>

    <div class="grey base mb-6" v-if="cart.cartItems[0]">
      <v-container class="pa-0">
        <ProductListSlider
          :layout="29"
          :userId="cart.user.userId"
          :warehouseId="cart.warehouse.warehouseId"
          :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
          :limit="24"
          :key="cart.cartItems[0].product.productId + '_3'"
          paginationClass="often-bought-together"
          :showArrows="!$vuetify.breakpoint.xsOnly"
          :showBullets="false"
          position="product_variations"
          v-intersect.once="handleView"
          @selectProduct="handleClick"
        >
          <h2 class="my-3">
            {{ $t("product.oftenBoughtTogether") }}
          </h2>
        </ProductListSlider>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.checkout {
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .qty-wrap {
    max-width: 160px;
  }
  .checkout-item-list {
    background: transparent;
    .v-subheader {
      background: transparent;
    }
  }

  .v-list {
    padding: 0;
  }
  .checkout-note {
    border-top: 8px solid $white;
  }
  .accept-replacement {
    //display: grid;
    //grid-template-columns: 1fr 4fr;
    align-items: center;
    column-gap: 10px;
    //margin: 0px 8px;
    padding: 10px;
    // border: 2px solid $primary;
    background-color: var(--v-grey-lighten3);
    border-radius: 4px;
    p {
      font-size: 14px;
      font-weight: 600;
    }
    .v-input--checkbox .v-label {
      font-weight: normal;
    }
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import productService from "@/commons/service/productService";
import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";

//import GiftList from "@/components/gift/GiftList.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import analyticsService from "~/service/analyticsService";
export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CheckoutCompleteProfile,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartItemList,
    ResponseMessage,
    //GiftList,
    GiftCode,
    // CategoryBlock,
    ProductListSlider
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      showPersonalCoupon: false,
      dialogInstance: null,
      productsAlternatives: null,
      minProfileLevel: global.config.minProfileLevel
    };
  },
  computed: {
    ...mapGetters({
      isPointPolvLiguriaActive: "custom/isPointPolvLiguriaActive",
      profileLevel: "cart/profileLevel"
    }),
    canShowPointsPolvLiguria() {
      return this.isPointPolvLiguriaActive && this.cart.user.companyId == 2;
    },
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    giftCertificatesReceived() {
      return this.cart.user?.giftCertificatesReceived;
    }
  },

  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart",
      addGiftCode: "cart/addGiftCode"
    }),
    async addUserGift(userGiftCertificateId) {
      await this.addGiftCode(userGiftCertificateId);
    },
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list",
        false
      );
      // var manualGiftCertificates = await RegistrationService.listGiftCertificate(
      //   "manual"
      // );

      this.giftCertificates = [
        ...giftCertificates
        // ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return giftCertificate.giftCode || hasUserGift.length > 0;
      });
    },

    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
          AnalyticsService.cartEvent("emptyCart", this.cart.cartItems);
        }
      }
    },
    async goToCart() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              _this.$router.push({
                name: "Cart"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    async removeGift(gift) {
      await this.removeGiftCode(gift);
      this.loadGiftCertificates();
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, "Checkout Product Slider");
      }
    },

    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        "Checkout Product Slider",
        payload.index
      );
    },
    async getProductAlternatives() {
      if (this.cart.cartItems && this.cart.cartItems.length == 0) {
        return;
      }
      const productIds = this.cart.cartItems
        .map(item => item.product?.productId)
        .filter(Boolean)
        .join(",");
      if (!productIds) return;
      // let productIds = "";
      // let comma = "";
      // for (const cartItem of this.cart?.cartItems) {
      //   productIds += comma + cartItem.product.productId;
      //   comma = ",";
      // }

      let res = await productService.getProductAlternativeList(
        false,
        productIds
      );
      if (res?.alternatives) {
        this.productsAlternatives = res.alternatives;
      }
    }
  },
  async mounted() {
    // if (!this.cart.user.profile.confirmed) {
    //   this.sendActivationMail();
    // }
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
    // AnalyticsService.beginCheckout(this.cart);
    AnalyticsService.viewCart(this.cart);
    this.checkOsx();
    //this.loadGiftCertificates();
    await this.getProductAlternatives();
  }
};
</script>
