<template>
  <v-card class="giftCode px-0" flat>
    <!-- <h2 class="font-weight-bold">
      {{ $t("checkout.giftCode.title") }}
    </h2> -->
    <div v-if="cart.user.fidelityCard" class="rounded grey lighten-1 pa-3 mb-3">
      <div class="text-body-2 text-uppercase font-weight-bold">
        {{ $t("checkout.usePoints") }}
      </div>
      <div v-if="cart.cartInfos.CAN_REDEEM == 'true'">
        <v-radio-group
          v-model="selectedGiftRadio"
          column
          hide-details
          class="gift-radio-group"
        >
          <v-radio
            :value="giftPoints[0].giftCertificateId"
            readonly
            v-on:click="sendPointsPromoCode(giftPoints[0])"
            ><template v-slot:label>
              <div class="pl-2 font-weight-bold">Non utilizzare punti</div>
            </template></v-radio
          >
          <v-radio
            v-for="(giftPoint, index) in giftPoints.slice(1, 4)"
            v-on:click="sendPointsPromoCode(giftPoint)"
            :key="index"
            :value="giftPoint.giftCertificateId"
            readonly
            :disabled="giftPoint.points > totalPoints98000002"
            ><template v-slot:label>
              <div class="points">{{ giftPoint.points }} punti</div>
              <div>
                <div class="title" v-html="giftPoint.title"></div>
                <div class="desc">{{ giftPoint.description }}</div>
              </div>
            </template></v-radio
          >
          <v-radio
            v-if="
              cart.user.companyId == 2 &&
                cart.user.fidelityCard &&
                pointsAvailable86000004 > 0
            "
            :value="giftPoints[4].giftCertificateId"
            readonly
            v-on:click="sendPointsPromoCode(giftPoints[4])"
            ><template v-slot:label>
              <div class="pl-2 font-weight-bold">
                Sconto<br />
                <span class="desc">Punti polverizzati</span>
              </div>
            </template></v-radio
          >
        </v-radio-group>
      </div>
      <div v-else>{{ $t("checkout.usePointsInCooperative") }}</div>
    </div>

    <div v-if="giftCertificates && giftCertificates.length >= 0">
      <div class="text-body-2 text-uppercase font-weight-bold">
        {{ $t("checkout.giftCodeSubtitle") }}
      </div>
      <!-- {{giftCertificates}} -->
      <gift-row
        v-for="gift in giftCertificates"
        :key="gift.userGiftCertificateId"
        :giftCertificate="gift"
        @add="$emit('add')"
        @remove="$emit('remove')"
      />
    </div>
    <div class="d-flex align-center">
      <v-text-field
        class="mt-1 white"
        outlined
        dense
        hide-details
        v-model="giftCode"
        :label="$t('checkout.giftCode.title')"
        :placeholder="$t('checkout.giftCode.title')"
      >
        <template v-slot:append class="mt-0">
          <v-btn
            class="applyBtn mt-0"
            tile
            depressed
            height="38"
            medium
            v-on:click="sendGiftCode(giftCode)"
            :disabled="!giftCode"
            color="primary"
            >{{ $t("checkout.giftCode.apply") }}</v-btn
          >
        </template></v-text-field
      >
    </div>
  </v-card>
</template>
<style scoped lang="scss">
.giftCode {
  :deep(.v-input__append-inner) {
    margin-top: 0 !important;
  }
  .applyBtn {
    margin-top: 0;
    top: 1px;
    right: -11px;
  }
  .gift-radio-group {
    .points {
      color: var(--v-secondary-base);
      font-size: 14px;
      font-weight: 700;
      flex-basis: 35%;
      padding-left: 10px;
    }
    .title {
      text-transform: uppercase;
      font-size: 14px !important;
      font-weight: 700;
      color: var(--v-primary-base);
      span {
        font-size: 12px !important;
        background-color: red;
      }
    }
    .desc {
      font-size: 12px !important;
      font-weight: 700;
    }
    .v-radio--is-disabled {
      filter: grayscale(100%);
      opacity: 0.6;
    }
  }
}
</style>
<script>
import GiftRow from "./GiftRow.vue";

import { mapActions, mapState } from "vuex";
import get from "lodash/get";

export default {
  components: { GiftRow },
  name: "GiftCode",
  props: { giftCertificates: { type: Array, required: true } },
  data() {
    return {
      giftCode: "",
      loadingGiftPoints: false,
      selectedGiftRadio: null,
      cashbackGiftCertificateId: 1242,
      cashbackGiftCertificate: null,
      giftPoints: [
        {
          giftCertificateId: -1,
          used: true
        },
        {
          giftCertificateId: 10,
          points: 500,
          value: "500PTX",
          title: "5% <span>DI SCONTO</span>",
          description: "Massimo 5€"
        },
        {
          giftCertificateId: 11,
          points: 1400,
          value: "1400PTX",
          title: "15% <span>DI SCONTO</span>",
          description: "Massimo 15€"
        },
        {
          giftCertificateId: 12,
          points: 2500,
          value: "2500PTX",
          title: "30% <span>DI SCONTO</span>",
          description: "Massimo 30€"
        },
        {
          giftCertificateId: 105,
          points: 0,
          value: "POLV_LIG",
          title: "Sconto",
          description: "Punti polverizzati"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    pointsAvailable98000002() {
      let points = get(this.cart, "userPoints['98000002'].pointsAvailable", 0);
      return points;
    },
    totalPoints98000002() {
      let pointsAvailable = get(
        this.cart,
        "userPoints['98000002'].pointsAvailable",
        0
      );
      let pointsUsed = get(this.cart, "userPoints['98000002'].pointsUsed", 0);
      return pointsAvailable + pointsUsed;
    },
    pointsAvailable86000004() {
      let points = get(this.cart, "userPoints['86000004'].pointsAvailable", 0);
      return points;
    },
    cashbackValue() {
      if (
        (this.cart.userPoints["86000051"] &&
          this.cart.userPoints["86000051"].pointsAvailable) ||
        this.cashbackGiftCertificate
      ) {
        return this.cashbackGiftCertificate
          ? this.cashbackGiftCertificate.total
          : this.cart.userPoints["86000051"].pointsAvailable / 100 >
            this.cart.grossTotal - this.cart.packageTotal
          ? this.cart.grossTotal - this.cart.packageTotal
          : this.cart.userPoints["86000051"].pointsAvailable / 100;
      } else return null;
    },
    usedGiftPoints() {
      return this.giftPoints.find(item => item.used === true);
    }
  },
  methods: {
    ...mapActions({
      addGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async sendGiftCode(gift) {
      await this.addGiftCode(gift);
      this.giftCode = "";
      this.$emit("addGiftCode");
    },
    async sendPointsPromoCode(gift) {
      let _this = this;
      if (
        gift &&
        gift.giftCertificateId != -1 &&
        gift.giftCertificateId != this.selectedGiftRadio
      ) {
        if (
          _this.usedGiftPoints &&
          _this.usedGiftPoints.userGiftCertificateId
        ) {
          await _this.removeGiftCode(
            _this.usedGiftPoints.userGiftCertificateId
          );
          _this.loadGiftCertificates();
        }
        await _this.sendGiftCode(gift.value);
        _this.loadGiftCertificates();
      } else if (this.usedGiftPoints) {
        await this.removeGiftCode(this.usedGiftPoints.userGiftCertificateId);
        this.loadGiftCertificates();
      }
    },
    loadGiftCertificates() {
      this.giftPoints.forEach(g => {
        g.used = false;
        g.userGiftCertificateId = null;
      });
      let found = false;
      this.cart.giftCertificates.forEach(gc => {
        this.giftPoints.forEach(gp => {
          if (gc.giftCertificateId == gp.giftCertificateId) {
            gp.used = true;
            this.selectedGiftRadio = gp.giftCertificateId;
            gp.userGiftCertificateId = gc.userGiftCertificateId;
            found = true;
          }
        });
        if (this.cashbackGiftCertificateId == gc.giftCertificateId) {
          this.cashbackGiftCertificate = gc;
        }
      });
      if (!found) {
        this.giftPoints[0].used = true;
        this.selectedGiftRadio = this.giftPoints[0].giftCertificateId;
      }
    }
  },
  created() {
    this.loadGiftCertificates();
  }
};
</script>
